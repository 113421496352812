<template>
  <div>
    <b-row class="align-items-center">
      <b-col md="4">
        <b-form-group
          label="Campus"
          invalid-feedback="Campus is required."
          ref="camp"
        >
          <v-select
            v-model="currentCamp"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="campuses"
            label="branch"
            :reduce="(opt) => opt.id"
            :clearable="false"
            placeholder="Select campus"
          />
        </b-form-group>
      </b-col>
      <b-button
        class="ml-1"
        :disabled="dataLoading"
        variant="primary"
        @click="LoadData()"
      >
        Show
      </b-button>
    </b-row>
    <b-row v-if="dataLoading" align-h="center">
      <b-spinner
        class="m-5"
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <template>
      <b-row class="mt-1">
        <b-col xl="9" lg="8">
          <b-card no-body>
            <b-card-header class="p-1">
              <h4 class="mb-0">Strength</h4>
            </b-card-header>
            <b-table
              class=""
              show-empty
              :items="items"
              :fields="fields"
              responsive
              hover
            >
              <template #table-busy>
                <div
                  class="d-flex justify-content-center mb-3"
                  style="margin-top: 50px"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>

              <template #cell(class)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(totalStudents)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(present)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(registered)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(left)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col xl="3" lg="4">
          <b-card>
            <vue-apex-charts
              type="radialBar"
              height="270"
              :options="chartOptions"
              :series="[67]"
            />
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BSpinner,
  BBadge,
  BFormInput,
  BFormGroup,
  BForm,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BSpinner,
    BBadge,
    BFormInput,
    BFormGroup,
    BForm,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    vSelect,
    flatPickr,
    VueApexCharts,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      dataLoading: false,
      campuses: [],
      currentCamp: this.$store.state.userData.cId,
      items: [],
      fields: [
        { label: "class", key: "class" },
        { label: "total Students", key: "totalStudents" },
        { label: "present", key: "present" },
        { label: "registered", key: "registered" },
        { label: "left", key: "left" },
      ],
      chartOptions: {
        plotOptions: {
          radialBar: {
            size: 150,
            offsetY: 0,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "65%",
            },
            track: {
              // background: "#fff",
              strokeWidth: "100%",
            },
            dataLabels: {
              name: {
                offsetY: -5,
                color: "#5e5873",
                fontSize: "1rem",
              },
              value: {
                offsetY: 15,
                color: "#5e5873",
                fontSize: "1.714rem",
                formatter: function (val) {
                  return val.toFixed(2) + "%";
                },
              },
            },
          },
        },
        colors: [$themeColors.success],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          // dashArray: 8,
          lineCap: "round",
        },
        labels: ["Revenue"],
      },
    };
  },
  created() {
    this.LoadCampuses();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async LoadData() {
      console.log(this.currentCamp);
    },

    async LoadCampuses() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
    },
  },
};
</script>
<style></style>
